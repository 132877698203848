import axios from "axios";
import React, { useState } from "react";

const UseData = () => {
	const [data, setData] = useState();
	React.useEffect(() => {
		axios
			.get(`https://digitizit.onrender.com/alluser/owner`)
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return [data];
};

export default UseData;
