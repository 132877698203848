import React from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { useAPI } from "../../../../apiContext";

const CopyRight = () => {
	const { data } = useAPI();
	return (
		<>
			{data?._id && (
				<div style={{ backgroundColor: "rgb(0 0 0 / 5%)" }}>
					<Divider className='borderColor' />
					<Typography
						variant='body1'
						component='div'
						className='textColor'
						sx={{ py: 1 }}>
						Get your own card{" "}
						<a
							className='textColor'
							href='https://digitizit.net'
							target='_blank'
							style={{ fontWeight: "bold" }}
							rel='noreferrer'>
							digitizit
						</a>
						<br />
						&copy;2019-2022 All Right Reserved By{" "}
						<a
							className='textColor'
							href='https://digitizit.net'
							starget='_blank'
							style={{ fontWeight: "bold" }}
							rel='noreferrer'>
							digitizit
						</a>
					</Typography>
				</div>
			)}
		</>
	);
};

export default CopyRight;
