import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useAPI } from "../../../../apiContext";

const MoreLinks = () => {
	const { data } = useAPI();
	return (
		<Container sx={{ my: 2 }}>
			{data?._id && (
				<Grid container spacing={2}>
					{data?.links?.link1 !== "off" && (
						<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
							<Button
								sx={{ width: "100%", height: "100%", borderRadius: 3 }}
								onClick={() => window.open(data?.links?.link1, "_blank")}
								variant='outlined'>
								Want to Become A Notary? Book A Discovery Call.
							</Button>
						</Grid>
					)}
					{data?.links?.link2 !== "off" && (
						<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
							<Button
								sx={{ width: "100%", height: "100%", borderRadius: 3 }}
								onClick={() => window.open(data?.links?.link2, "_blank")}
								variant='outlined'>
								Experience an Online Notarization Session (for clients/by
								appointment only)
							</Button>
						</Grid>
					)}
					{data?.links?.link3 !== "off" && (
						<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
							<Button
								sx={{ width: "100%", height: "100%", borderRadius: 3 }}
								onClick={() => window.open(data?.links?.link3, "_blank")}
								variant='outlined'>
								Join Our Online Notary Team (for Notaries)
							</Button>
						</Grid>
					)}
				</Grid>
			)}
		</Container>
	);
};

export default MoreLinks;
