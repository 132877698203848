import {
	Backdrop,
	Box,
	Container,
	Divider,
	Fab,
	Fade,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PropagateLoader from "react-spinners/PropagateLoader";
import QRCode from "react-qr-code";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useAPI } from "../../../../apiContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Buttons = () => {
	const { data } = useAPI();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [openMail, setOpenMail] = React.useState(false);
	const handleOpenMail = () => setOpenMail(true);
	const handleCloseMail = () => setOpenMail(false);
	const downloadTxtFile = () => {
		const element = document.createElement("a");
		const file = new Blob(
			[
				`
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${data?.headline?.title}
N;CHARSET=UTF-8:;${data?.headline?.title};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${data?.email}
PHOTO;ENCODING=b;TYPE=X-ICON:IMAGEDATA..
TEL;TYPE=HOME,VOICE:${data?.phone || ""}
TITLE;CHARSET=UTF-8:${data?.headline?.subtitle} 
ORG;CHARSET=UTF-8:${data?.headline?.title}
URL;type=WORK;CHARSET=UTF-8:${window.location.origin.toString()}
END:VCARD
`,
			],
			{
				type: "text/x-vcard",
			},
		);
		element.href = URL.createObjectURL(file);
		element.download = `${data?.headline?.title}.vcf`;
		document.body.appendChild(element);
		element.click();
	};

	const [show, setShow] = useState(true);
	const url = window.location.pathname;
	useEffect(() => {
		if (url.includes("/dashboard")) {
			setShow(false);
		}
	}, [url]);

	return (
		<Container>
			{data?._id ? (
				<>
					<Grid container spacing={2} className='icons'>
						{data?.links?.website !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.website, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/SignaturePros.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Website
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.email1 !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`mailto:${data?.links?.email1}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/SignaturePros.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Email
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.website2 !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.website2, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/TAP-LOGO.webp"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Website
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.email2 !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`mailto:${data?.links?.email2}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/TAP-LOGO.webp"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Email
									</Typography>
								</Box>
							</Grid>
						)}{" "}
						{data?.links?.nna !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.nna, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/nna.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										NNA
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.email !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`mailto:${data?.links?.email}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/email.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Email
									</Typography>
								</Box>
							</Grid>
						)}{" "}
						{data?.links?.call !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`tel:${data?.links?.call}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/call.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Call
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.facebook !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.facebook, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/facebook.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Facebook
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.whatsapp !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(
											`https://api.whatsapp.com/send?phone=${data?.links?.whatsapp}`,
											"_blank",
										)
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/whatsapp.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Whatsapp
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.instagram !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.instagram, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/instagram.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Instagram
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.sms !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`sms:${data?.links?.sms}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/sms.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										SMS
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.twitter !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.twitter, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/twitter.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Twitter
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.youtube !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.youtube, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/youtube.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Youtube
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.linkedin !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.linkedin, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/linkedIn.png"}
										alt='linkedIn'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										LinkedIn
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.pinterest !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.pinterest, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/pinterest.png"}
										alt='pinterest'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Pinterest
									</Typography>
								</Box>
							</Grid>
						)}{" "}
						{data?.links?.clubhouse !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.clubhouse, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/clubhouse.png"}
										alt='clubhouse'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Clubhouse
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.cashApp !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.cashApp, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/cashapp.png"}
										alt='cashApp'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Cash App
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.paypal !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.paypal, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/paypal.png"}
										alt='paypal'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Paypal
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.zelle !== "off" && (
							<Grid item xs className='animate__animated animate__backInLeft'>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(data?.links?.zelle, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/zelle.png"}
										alt='zelle'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Zelle
									</Typography>
								</Box>
							</Grid>
						)}
						<Grid item xs className='animate__animated animate__backInLeft'>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									cursor: "pointer",
								}}
								onClick={handleOpenMail}>
								<img
									className='borderColor'
									style={{
										width: "56px",
										height: "56px",
										border: "1px solid",
										borderRadius: "50%",
									}}
									src={"/post.png"}
									alt='clubhouse'
								/>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Mailling Address
								</Typography>
							</Box>
						</Grid>
						{show && (
							<Grid
								className='animate__animated animate__backInLeft'
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab onClick={handleOpen} color='primary' aria-label='add'>
									<QrCode2Icon />
								</Fab>
								<Typography
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									className='textColor'
									display='block'>
									QR
								</Typography>
							</Grid>
						)}
						{data?.links?.vcf !== "off" && (
							<>
								{show && (
									<Grid className='contactList' item md={12} xs={12}>
										<Fab
											className='animate__animated animate__rubberBand'
											onClick={downloadTxtFile}
											variant='extended'
											sx={{
												px: 4,
												fontWeight: "bold",
											}}>
											<PermContactCalendarIcon sx={{ mr: 1.5 }} />
											Add to contact list
										</Fab>
									</Grid>
								)}
							</>
						)}
					</Grid>
					<Modal
						aria-labelledby='transition-modal-title'
						aria-describedby='transition-modal-description'
						open={open}
						onClose={handleClose}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}>
						<Fade in={open}>
							<Box sx={style}>
								<Typography
									sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
									className='textColor'
									id='transition-modal-title'
									variant='h5'>
									Scan Me
								</Typography>
								<QRCode
									fgColor={`${data?.color?.color1st}`}
									value={`${window.location.origin.toString()}`}
								/>
							</Box>
						</Fade>
					</Modal>
					<Modal
						aria-labelledby='transition-modal-title'
						aria-describedby='transition-modal-description'
						open={openMail}
						onClose={handleCloseMail}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}>
						<Fade in={openMail}>
							<Box sx={style} style={{ width: "70%" }}>
								<Typography
									sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
									className='textColor'
									id='transition-modal-title'
									variant='h5'>
									Mailing Address
									<Divider className={`${data?.color?.hr}`} />
								</Typography>
								<Typography
									sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
									className='textColor'
									id='transition-modal-title'
									variant='h6'>
									5557 Baltimore Ave. Ste 500-1401 Hyattsville, MD 20781
								</Typography>
							</Box>
						</Fade>
					</Modal>
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Buttons;
