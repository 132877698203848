import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import HomepageMain from "./Pages/Homepage/HomepageMain";
import Login from "./Pages/Login/Login";
import Bg from "./Pages/Dashboard/Bg/Bg";
import useData from "./useData";
import NotFound from "./Pages/NotFound/NotFound";
import RequireAuth from "./RequireAuth/RequireAuth";
import { Typography } from "@mui/material";
import AddVideo from "./Pages/Dashboard/AddVideo/AddVideo";
import { APIContextProvider } from "./apiContext";
import MyProfile from "./Pages/Dashboard/MyProfile/MyProfile";
import ManageMap from "./Pages/Dashboard/ManageMap/ManageMap";
import ClockLoader from "react-spinners/ClockLoader";
import { Wave } from "react-animated-text";
import "animate.css";

function App() {
	const [data] = useData();
	React.useEffect(() => {
		let timerId = setTimeout(() => {
			if (!data) {
				window.location.reload();
			}
		}, 5000);

		// Clear the timeout when the component unmounts
		return () => clearTimeout(timerId);
	}, [data]);
	return (
		<div className='App' style={{ backgroundImage: `url(/bg-img.jpg)` }}>
			{data ? (
				<>
					{document.documentElement.style.setProperty(
						"--color1st",
						`${data?.color?.color1st}`,
					)}
					{document.documentElement.style.setProperty(
						"--hr",
						`${data?.color?.hr}`,
					)}
					{data?._id ? (
						<>
							<APIContextProvider>
								<BrowserRouter>
									<Routes>
										<Route path='*' element={<NotFound />} />
										<Route path='/' element={<HomepageMain url={`/`} />} />
										<Route path='/login' element={<Login />} />
										<Route
											exact
											path='/dashboard'
											element={
												<RequireAuth userInfo={data}>
													<Dashboard url={window?.location.origin.toString()} />
												</RequireAuth>
											}>
											<Route
												path='/dashboard'
												element={
													<RequireAuth userInfo={data}>
														<MyProfile />
													</RequireAuth>
												}
											/>
											<Route
												path='/dashboard/bannerphoto'
												element={<BannerPhoto />}
											/>
											<Route
												path='/dashboard/profilephoto'
												element={<ProfilePhoto />}
											/>

											<Route
												path='/dashboard/headline'
												element={<Headline />}
											/>

											<Route
												path='/dashboard/buttonslink'
												element={<ButtonsLink />}
											/>

											<Route
												path='/dashboard/colorchange'
												element={<ColorChange />}
											/>
											<Route path='/dashboard/details' element={<Details />} />
											<Route
												path='/dashboard/managemap'
												element={<ManageMap />}
											/>
											<Route
												path='/dashboard/galleryphoto'
												element={<Galleryphoto />}
											/>

											<Route path='/dashboard/bg' element={<Bg />} />

											<Route
												path='/dashboard/allreviews'
												element={<AllReviews />}
											/>
											<Route
												path='/dashboard/allemails'
												element={<AllMails />}
											/>

											<Route
												path='/dashboard/managevideo'
												element={<AddVideo />}
											/>
										</Route>
									</Routes>
								</BrowserRouter>
							</APIContextProvider>
						</>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								minHeight: "100vh",
							}}>
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h3'
								component='div'>
								OPPS!
							</Typography>
							<br />
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h5'
								component='div'>
								{data}
							</Typography>
						</div>
					)}
				</>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<ClockLoader size={150} color={"#199CAB"} />
					<Typography
						sx={{
							fontWeight: 500,
							fontSize: "2rem",
							mt: 2,
							color: "#199CAB",
						}}
						component='div'>
						<Wave
							text={"Loading..."}
							effect='jump'
							effectChange={0.5}
							delay={2}
						/>
					</Typography>
				</div>
			)}
		</div>
	);
}

export default App;
