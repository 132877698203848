import React, { useContext, useState, createContext } from "react";
import axios from "axios";

const APIContext = createContext();

export function APIContextProvider({ children }) {
	const [data, setData] = useState();
	React.useEffect(() => {
		axios
			.get(`https://digitizit.onrender.com/alluser/owner`)
			.then((res) => {
				setData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<APIContext.Provider
			value={{
				data: data,
			}}>
			{children}
		</APIContext.Provider>
	);
}

export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
