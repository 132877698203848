import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
	apiKey: "AIzaSyBJ7fEA6-9EcpMUj0bPR0Imcpgpn1bYrUo",
	authDomain: "digitizit-dbc.firebaseapp.com",
	projectId: "digitizit-dbc",
	storageBucket: "digitizit-dbc.appspot.com",
	messagingSenderId: "805409089507",
	appId: "1:805409089507:web:0f4508ef7b4b53874c62d6",
	measurementId: "G-6YD5PD7GX4",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
