import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Box,
	Divider,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const Contact = () => {
	const { data } = useAPI();
	const id = data?._id;
	const form = useRef();
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const onSubmit = ({ userName, userEmail, phoneNumber, subject, message }) => {
		const datas = {
			mailId: `mail${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userEmail,
			phoneNumber,
			subject,
			message,
			submitTime: new Date().toLocaleString(),
			receiver: data?.email,
		};
		setSubmitting(true);

		axios
			.put(`https://digitizit.onrender.com/emails/${id}`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Mail Sent Successfully",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Container sx={{ pt: 5, pb: 2 }}>
			{data?._id ? (
				<>
					{!data?.headline?.contact === "off" ? (
						""
					) : (
						<>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									className='textColor'
									sx={{ fontWeight: 900 }}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.contact}
									<Divider className={`${data?.color?.hr}`} />
								</Typography>
							</Box>
							<Typography>
								<Grid container spacing={2}>
									<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
										<form
											ref={form}
											onSubmit={handleSubmit(onSubmit)}
											method='post'>
											<Grid container spacing={2}>
												<Grid item md={6} xs={12}>
													<Grid container spacing={2}>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%" }}
																id='outlined-basic'
																name='UserName'
																label='Name'
																{...register("userName", { required: true })}
															/>
														</Grid>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%" }}
																id='outlined-basic'
																name='UserEmail'
																type='email'
																label='Email'
																{...register("userEmail", { required: true })}
															/>
														</Grid>
														<Grid item md={12} xs={12}>
															<TextField
																sx={{ width: "100%" }}
																id='outlined-basic'
																name='PhoneNumber'
																type='tel'
																label='Phone Number'
																{...register("phoneNumber")}
															/>
														</Grid>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%", mb: { md: 2, xs: 0 } }}
																id='outlined-basic'
																label='Subject'
																name='Subject'
																{...register("subject", { required: true })}
															/>
														</Grid>
													</Grid>
												</Grid>

												<Grid item md={6} xs={12}>
													<TextField
														required
														sx={{ width: "100%", mb: 2 }}
														id='"outlined-multiline-flexible'
														label='Message'
														name='Message'
														multiline
														rows={10.2}
														{...register("message", { required: true })}
													/>
												</Grid>
											</Grid>
											<Button
												className='button'
												type='submit'
												variant='contained'
												sx={{
													width: "100%",
													mb: 2,
													py: 2,
													fontWeight: "bold",
													borderRadius: "9px",
													border: "2px solid",
												}}>
												Submit
											</Button>
										</form>
									</Grid>
								</Grid>
							</Typography>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Contact;
