import {
	Backdrop,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Review from "./Review";
import GetAuth from "../../../GetAuth";

const AllReviews = () => {
	const [open, setOpen] = React.useState(false);
	const [viewId, setViewId] = useState();
	const handleOpen = (reviewViewId) => {
		setViewId(reviewViewId);
		setOpen(true);
	};
	const [id, setId] = useState();
	const [reviews, setReviews] = useState();
	const [deleted, setDeleted] = useState(false);
	const { token } = GetAuth();
	useEffect(() => {
		axios
			.get(`https://digitizit.onrender.com/alluser/owner`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				setId(res.data?._id);
				setReviews(res.data?.reviews?.reverse());
			})
			.catch((error) => {
				console.log(error);
			});
	}, [deleted, token]);

	const handleDelete = (deletedReviewId) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(
						`https://digitizit.onrender.com/deleteReview/${id}/${deletedReviewId}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That Review has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	return (
		<>
			<Paper
				className='container'
				sx={{ overflow: "auto", maxHeight: "85vh", maxWidth: "90vw", m: 2 }}>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center' colSpan={5}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h4'>
									All Reviews
								</Typography>
								<Typography className='textColor'>
									Last changed in {reviews?.[0]?.submitTime || "N/A"}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Photo</TableCell>
							<TableCell align='left'>Name</TableCell>
							<TableCell align='left'>Rec Time</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					{reviews?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{reviews?.map((review, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell>
										<img
											src={review?.userPhoto || "N/A"}
											alt=''
											width='50px'
											height='50px'
											style={{ borderRadius: "50%" }}
										/>
									</TableCell>
									<TableCell align='left'>
										{review?.userName || "N/A"}
									</TableCell>
									<TableCell align='left'>
										{review?.submitTime || "N/A"}
									</TableCell>
									{/*<TableCell align='left'>{review?.rating}</TableCell>
											 <TableCell align='left'>
												{review?.userReview || "N/A"}
											</TableCell> */}
									<TableCell align='center'>
										<Button
											onClick={() => handleOpen(review?.reviewId)}
											className='button border'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												m: 0.5,
											}}
											variant='contained'>
											<RemoveRedEyeIcon />
										</Button>
										<Button
											className='button border'
											onClick={() => handleDelete(review?.reviewId)}
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
											}}
											variant='contained'>
											<DeleteIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								{/*<TableCell align='left'>N/A</TableCell>
										 <TableCell align='left'>N/A</TableCell> */}
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!reviews}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && (
				<Review
					open={open}
					setOpen={setOpen}
					viewId={viewId}
					id={id}
					token={token}
				/>
			)}
		</>
	);
};

export default AllReviews;
