import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet } from "react-router-dom";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import PortraitIcon from "@mui/icons-material/Portrait";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FeedIcon from "@mui/icons-material/Feed";
import InfoIcon from "@mui/icons-material/Info";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Avatar, Backdrop, Button, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../../Firebase/firebase.config";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import GetAuth from "../../GetAuth";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useSendEmailVerification } from "react-firebase-hooks/auth";
import FaceSharpIcon from "@mui/icons-material/FaceSharp";
import UseData from "../../useData";
import MapIcon from "@mui/icons-material/Map";
import ClockLoader from "react-spinners/ClockLoader";
import { Wave } from "react-animated-text";

const drawerWidth = 240;

function Dashboard(props) {
	const [data] = UseData();
	const location = useLocation();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<Box className='dashboard'>
			<Typography
				sx={{
					py: 1.5,
					color: "white",
					width: "100%",
					fontWeight: "bold",
					fontSize: "22px !important",
				}}>
				DBC
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: 1,
				}}>
				<Avatar
					sx={{ border: "2px solid white", mb: 0.7 }}
					alt=''
					src={data?.profile?.imageLink2}
				/>
				<Typography sx={{ color: "white", fontSize: "17px !important" }}>
					{data?.headline?.title}
				</Typography>
			</Box>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to=''>
				<ListItem
					button
					className={location?.pathname === "/dashboard" && "activeButton"}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<FaceSharpIcon />
					</ListItemIcon>
					<ListItemText primary={"Profile"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='bannerphoto'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/bannerphoto" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<PhotoCameraBackIcon />
					</ListItemIcon>
					<ListItemText primary={"Banner Photo"} />
				</ListItem>
			</Link>

			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='profilephoto'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/profilephoto" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<PortraitIcon />
					</ListItemIcon>
					<ListItemText primary={"Profile Photo"} />
				</ListItem>
			</Link>

			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='galleryphoto'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/galleryphoto" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ViewCarouselIcon />
					</ListItemIcon>
					<ListItemText primary={"Gallery Photos"} />
				</ListItem>
			</Link>

			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='managevideo'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/managevideo" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<OndemandVideoIcon />
					</ListItemIcon>
					<ListItemText primary={"Gallery Videos"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='managemap'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/managemap" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<MapIcon />
					</ListItemIcon>
					<ListItemText primary={"Manage Map"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='colorchange'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/colorchange" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ColorLensIcon />
					</ListItemIcon>
					<ListItemText primary={"Color & Border"} />
				</ListItem>
			</Link>

			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='headline'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/headline" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<FeedIcon />
					</ListItemIcon>
					<ListItemText primary={"Headline"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='buttonslink'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/buttonslink" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<PostAddIcon />
					</ListItemIcon>
					<ListItemText primary={"Button Links"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='details'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/details" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<InfoIcon />
					</ListItemIcon>
					<ListItemText primary={"Details"} />
				</ListItem>
			</Link>

			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allreviews'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allreviews" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ReviewsIcon />
					</ListItemIcon>
					<ListItemText primary={"All Reviews"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allemails'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allemails" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ContactMailIcon />
					</ListItemIcon>
					<ListItemText primary={"All Mails"} />
				</ListItem>
			</Link>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;
	const { user, loading } = GetAuth();
	const [open, setOpen] = React.useState(!user?.emailVerified === true);
	const [sendEmailVerification, sending] = useSendEmailVerification(auth);

	if (loading || !user || !data) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<ClockLoader size={150} color={"#199CAB"} />
				<Typography
					sx={{ fontWeight: 500, fontSize: "2rem", mt: 2, color: "#199CAB" }}
					component='div'>
					<Wave text={"Loading..."} effect='jump' effectChange={0.5} delay={2} />
				</Typography>
			</div>
		);
	}
	if (data && data?.status === "Inactive") {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					minHeight: "100vh",
				}}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h3'
					component='div'>
					OPPS!
				</Typography>
				<br />
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h5'
					component='div'>
					Contact Admin to get access of the card
				</Typography>
			</div>
		);
	}

	return (
		<Box sx={{ display: "flex" }}>
			<>
				<CssBaseline />
				<AppBar
					position='fixed'
					className='bgColor'
					sx={{
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						padding: 0,
						ml: { sm: `${drawerWidth}px` },
					}}>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: "none" } }}>
							<MenuIcon />
						</IconButton>
						<Box display='flex' sx={{ flexGrow: 1, alignItems: "center" }}>
							<DashboardIcon sx={{ mr: 1 }} />
							<Typography variant='h6'>Dashboard</Typography>
						</Box>
						<Button
							onClick={() => signOut(auth)}
							color='inherit'
							className='logout'>
							LogOut
						</Button>
					</Toolbar>
				</AppBar>
				<Box
					/* onClick={() => setMobileOpen(false)} */
					component='nav'
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label='mailbox folders'>
					<Drawer
						container={container}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true,
						}}
						className='dashboard'
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}>
						{drawer}
					</Drawer>
					<Drawer
						variant='permanent'
						className='dashboard'
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}
						open>
						{drawer}
					</Drawer>
				</Box>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
					<Toolbar />
					{open && (
						<Alert
							sx={{
								width: "max-content",
								textAlign: "left",
								position: "fixed",
								top: " 15%",
								right: "50%",
								transform: "translate(50%,-15%)",
								zIndex: 9,
							}}
							action={
								<>
									<button
										style={{
											backgroundColor: "#F27471",
											padding: "7px",
											color: "white",
											borderRadius: "5px",
										}}
										size='small'
										onClick={() => sendEmailVerification()}>
										Resend
									</button>
									<button
										style={{
											backgroundColor: "#F27471",
											padding: "7px",
											color: "white",
											borderRadius: "5px",
										}}
										size='small'
										onClick={() => {
											setOpen(false);
										}}>
										X
									</button>
								</>
							}
							severity='error'>
							<AlertTitle>Warning</AlertTitle>
							<strong>Please verify your email</strong> — check inbox/spam
							folder!
						</Alert>
					)}
					<Backdrop
						sx={{
							color: "#fff",
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={sending}>
						<CircularProgress color='inherit' />
					</Backdrop>
					<Outlet></Outlet>
				</Box>
			</>
		</Box>
	);
}

Dashboard.propTypes = {
	window: PropTypes.func,
};

export default Dashboard;
