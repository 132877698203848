import React, { useEffect, useLayoutEffect, useState } from "react";
import Reviews from "./Components/Reviews/Reviews";
import About from "./Components/About/About";
import Banner from "./Components/Banner/Banner";
import Buttons from "./Components/Buttons/Buttons";
import Contact from "./Components/Contact/Contact";
import Gallery from "./Components/Gallery/Gallery";
import CopyRight from "./Components/CopyRight/CopyRight";
import Video from "./Components/Video/Video";
import Device from "react-device-frame";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import { Fab } from "@mui/material";
import ShareButton from "./Components/ShareButton/ShareButton";
import Map from "./Components/Map/Map";
import MoreLinks from "./Components/MoreLinks/MoreLinks";
import AOS from "aos";
import "aos/dist/aos.css";

const HomepageMain = ({ url }) => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	const [mobile, setMobile] = useState(true);
	useEffect(() => {
		AOS.init();
		setTimeout(function () {
			AOS.refresh();
		}, 500);
	});
	return (
		<div>
			{size?.[0] > 1080 && (
				<div
					style={{
						position: "fixed",
						right: "9px",
						top: "9px",
						zIndex: "9999",
					}}>
					{!mobile && (
						<Fab
							onClick={() => setMobile(true)}
							color='primary'
							aria-label='add'>
							<PhoneAndroidIcon />
						</Fab>
					)}
					{mobile && (
						<Fab
							onClick={() => setMobile(false)}
							color='primary'
							aria-label='add'>
							<DesktopWindowsIcon />
						</Fab>
					)}
				</div>
			)}
			{size?.[0] > 1080 && mobile ? (
				<div className='home'>
					<Device name='galaxy-note8' url={url} />
				</div>
			) : (
				<div style={{ overflow: "hidden" }}>
					<Banner />
					<Buttons />
					<div data-aos='zoom-out-left'>
						<MoreLinks />
					</div>

					<div data-aos='zoom-out-right'>
						<About />
					</div>

					<div data-aos='zoom-in-up'>
						<Video />
					</div>

					<div data-aos='zoom-in-left'>
						<Gallery />
					</div>

					<div data-aos='zoom-in-right'>
						<Map />
					</div>

					<div data-aos='zoom-out'>
						<Reviews />
					</div>

					<div data-aos='zoom-out'>
						<Contact />
					</div>
					<CopyRight />
					<ShareButton />
				</div>
			)}
		</div>
	);
};

export default HomepageMain;
