import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import GetAuth from "../../../GetAuth";

const Headline = () => {
	const [id, setId] = useState();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			title: "",
			subtitle: "",
			contact: "",
			review: "",
			gallery: "",
			aboutUs: "",
			video: "",
			findUs: "",
		},
	});
	const [info, setInfo] = useState();
	const { token } = GetAuth();
	useEffect(() => {
		axios
			.get(`https://digitizit.onrender.com/alluser/owner`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				reset(res.data?.headline);
				setId(res.data?._id);
				setInfo(res.data?.headline);
			});
	}, [reset, token]);
	const onSubmit = ({
		title,
		subtitle,
		contact,
		review,
		gallery,
		aboutUs,
		video,
		findUs,
	}) => {
		setSubmitting(true);
		const data = {
			type: "headline",
			title,
			subtitle,
			contact,
			review,
			gallery,
			aboutUs,
			video,
			findUs,
			submitTime: new Date().toLocaleString(),
		};
		axios
			.put(`https://digitizit.onrender.com/headline/${id}`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Headline
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={3} justifyContent='center'>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Title'
							label='Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("title", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='SubTitle'
							label='SubTitle'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("subtitle", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='About Us Title'
							label='About Us Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("aboutUs", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Gallery Title'
							label='Gallery Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("gallery", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Video Gallery Title'
							label='Video Gallery Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("video", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Find Us Title'
							label='Find Us Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("findUs", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Review Title'
							label='Review Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("review", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Contact Form Title'
							label='Contact Form Title'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("contact", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Headline;
