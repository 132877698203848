import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import GetAuth from "../../../GetAuth";

const ButtonsLink = () => {
	const [id, setId] = useState();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			website: "",
			website2: "",
			email: "",
			email1: "",
			email2: "",
			facebook: "",
			whatsapp: "",
			youtube: "",
			sms: "",
			call: "",
			twitter: "",
			instagram: "",
			pinterest: "",
			linkedin: "",
			clubhouse: "",
			cashApp: "",
			paypal: "",
			zelle: "",
			nna: "",
			link1: "",
			link2: "",
			link3: "",
		},
	});
	const [info, setInfo] = useState();
	const { token } = GetAuth();
	useEffect(() => {
		axios
			.get(`https://digitizit.onrender.com/alluser/owner`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				reset(res.data?.links);
				setId(res.data?._id);
				setInfo(res.data?.links);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [reset, token]);
	const onSubmit = ({
		website,
		website2,
		email,
		email1,
		email2,
		facebook,
		whatsapp,
		youtube,
		sms,
		call,
		twitter,
		instagram,
		pinterest,
		linkedin,
		abonnement,
		clubhouse,
		cashApp,
		paypal,
		zelle,
		nna,
		link1,
		link2,
		link3,
	}) => {
		setSubmitting(true);
		const data = {
			website,
			website2,
			email,
			email1,
			email2,
			facebook,
			whatsapp,
			youtube,
			sms,
			call,
			twitter,
			instagram,
			pinterest,
			linkedin,
			abonnement,
			clubhouse,
			cashApp,
			paypal,
			zelle,
			nna,
			link1,
			link2,
			link3,
			submitTime: new Date().toLocaleString(),
		};
		axios
			.put(`https://digitizit.onrender.com/links/${id}`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Button Links
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='website'
							label='Website Signature Pros'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("website", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='website2'
							label='Website Administrative Pros'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("website2", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='nna'
							label='NNA'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("nna", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Facebook'
							label='Facebook'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("facebook", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Email'
							label='Email'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Email'
							label='Email Signature Pros'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email1", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Email'
							label='Email Administrative Pros'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email2", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='WhatsApp'
							label='WhatsApp'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("whatsapp", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Youtube'
							label='Youtube'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("youtube", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='CAll'
							label='CALL'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("call", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='SMS'
							label='SMS'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("sms", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Twitter'
							label='Twitter'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("twitter", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Instagram'
							label='Instagram'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("instagram", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='pinterest'
							label='Pinterest'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("pinterest", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='linkedin'
							label='LinkedIn'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("linkedin", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='clubhouse'
							label='Clubhouse'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("clubhouse", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='cashApp'
							label='Cash App'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("cashApp", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='paypal'
							label='Paypal'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("paypal", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='zelle'
							label='Zelle '
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("zelle", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='link1'
							label='Want to Become A Notary?'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("link1", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='link2'
							label='Experience an Online Notarization Session.'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("link2", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='link3'
							label='Join Our Online Notary Team. '
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("link3", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ButtonsLink;
