import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import GetAuth from "../../../GetAuth";
import styled from "@emotion/styled";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const Details = () => {
	const form = useRef();
	const { token } = GetAuth();
	const [info, setInfo] = useState();
	const [id, setId] = useState();
	const [imageLink2, setImageLink2] = useState(null);
	const Input = styled("input")({
		display: "none",
	});
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			details: "",
		},
	});
	useEffect(() => {
		axios
			.get(`https://digitizit.onrender.com/alluser/owner`)
			.then((res) => {
				reset(res.data?.details);
				setId(res.data?._id);
				setInfo(res.data?.details);
			});
	}, [reset, submitting]);
	const [photo, setPhoto] = useState(info?.imageLink2);
	React.useEffect(() => {
		setPhoto(info?.imageLink2);
	}, [info?.imageLink2, submitting]);
	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "DetailsLogo");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/du8x1jj27/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const onSubmit = ({ details }) => {
		setSubmitting(true);
		const data = {
			details,
			imageLink2: imageLink2 || photo,
			submitTime: new Date().toLocaleString(),
		};
		axios
			.put(`https://digitizit.onrender.com/details/${id}`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your details updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Details
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>

			<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={3}>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							required
							name='Details'
							label='Details'
							fullWidth
							multiline
							rows={7}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("details", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						{!loading && !imageLink2 && (
							<img src={photo} style={{ width: "300px" }} alt='' />
						)}

						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ my: 1, mx: "auto" }}>
							{!loading && !imageLink2 && (
								<label
									className='bgColor'
									htmlFor='icon-button-file'
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										margin: "0 9px",
										borderRadius: 5,
									}}>
									<Input
										accept='image/*'
										id='icon-button-file'
										type='file'
										onChange={uploadImage}
									/>
									<Typography
										sx={{ my: 2, ml: 2, color: "white" }}
										variant='body1'
										component='div'
										gutterBottom>
										Update Your Logo*
									</Typography>
									<IconButton
										sx={{ mx: 2 }}
										color='primary'
										aria-label='upload picture'
										component='span'>
										<AttachFileIcon
											fontSize='large'
											sx={{ fontWeight: "bold" }}
										/>
									</IconButton>
								</label>
							)}

							{loading ? (
								<Box sx={{ my: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<img src={imageLink2} style={{ width: "300px" }} alt='' />
							)}
						</Box>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Update
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Details;
