import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import GetAuth from "../GetAuth";
import UseData from "../useData";
import ClockLoader from "react-spinners/ClockLoader";
import { Wave } from "react-animated-text";
import { Typography } from "@mui/material";

const RequireAuth = ({ children }) => {
	const [data] = UseData();
	const { user, loading } = GetAuth();
	const location = useLocation();
	if (loading) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<ClockLoader size={150} color={"#199CAB"} />
				<Typography
					sx={{ fontWeight: 500, fontSize: "2rem", mt: 2, color: "#199CAB" }}
					component='div'>
					<Wave
						text={"Loading..."}
						effect='jump'
						effectChange={0.5}
						delay={2}
					/>
				</Typography>
			</div>
		);
	}
	if (!user) {
		return (
			<>
				{data && <Navigate to={"/login"} state={{ from: location }} replace />}
			</>
		);
	}

	return children;
};

export default RequireAuth;
